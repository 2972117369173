import React, { Component } from "react";

class ExperienceDesign extends Component {
  render() {
    return (
      <ul className="c-list c-list--large c-list--glove">
        <li className="c-list__item">Wireframing, Style Guides, Interactive Mockups, Pitch Decks</li>
        <li className="c-list__item">Design Systems Development &amp; Documentation, Animation</li>
        <li className="c-list__item">Usability Testing, Accessability (dyslexia, color-blindness, etc), User Support</li>
        <li className="c-list__item">Visual Design, Marketing Strategy, A/B Testing, Software Analytics</li>
      </ul>
    );
  }
}

export default ExperienceDesign;
